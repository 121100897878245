<template>
  <section class="Academy">
    <div class="title">
      <h2>{{ $t("home.academy.title") }}</h2>
    </div>
    <div class="grid">
      <router-link :to="`/academy/post/${post.id}`" v-for="post in news"
                   :key="post.id"
                   class="grid__item"
                   data-aos="fade-up"
                   data-aos-duration="900">
        <div class="grid__item-img">
          <img :src="post.img" alt="preview">
        </div>
        <div class="grid__item-name">
          {{ $i18n.locale === 'ru' ? post.name_ru : post.name }}
        </div>
        <div class="grid__item-date">
          {{ formattedDate(post.created) }}
        </div>
      </router-link>
    </div>
    <router-link :to="{name: 'showCategory', params: { id: '1' }}" class="view__all">{{ $t("home.academy.more") }}</router-link>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import {getLocalStorageElement} from "@/helpers/localstorage";
import {LSTORAGE} from "@/config/constants";

export default {
  name: "Academy",
  mounted() {
    this.$store.dispatch("fetchLandingAcademyPosts", 3)
  },
  computed: {
    ...mapGetters({
      news: "allSpecificPosts",
    }),
  },
  methods: {
    formattedDate(date) {
      return this.$moment(date * 1000).format("DD MMMM YYYY")
    },
  },
}
</script>

<style lang="scss">
.Academy {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background: #1E2930;
  height: auto;
  padding: 100px 32px 50px;

  @media (max-width: 992px) {
    padding-top: 30px;
    margin-top: 50px;
  }


  .view__all {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 0 ;
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: #FFFFFF;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 100%;
    text-transform: uppercase;
    border-radius: 100px;
  }


  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 70px;

    h2 {
      font-weight: 400;
      font-size: 72px;
      line-height: 110%;
      letter-spacing: -0.04em;
      color: #FFFFFF;

      @media (max-width: 768px) {
        font-size: 60px;
      }

      @media (max-width: 560px) {
        font-size: 32px;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 64px;
    grid-row-gap: 24px;
    width: 100%;
    margin: auto 0;

    &__item {
      &-img {
        max-height: 220px;
        border-radius: 16px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-name {
        margin: 24px 0 8px;
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;

        @media (max-width: 992px) {
          font-size: 20px;
        }
      }

      &-date {
        font-size: 16px;
        line-height: 130%;
        color: rgba(255, 255, 255, 0.4);
      }
    }

    @media (max-width: 992px) {
      grid-gap: 16px;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 992px) {
    height: auto;
  }

  .btn__default {
    margin: auto 0 32px;

    @media (max-width: 992px) {
      margin: 32px 0;
    }
  }
}
</style>
