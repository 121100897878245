<template>
  <div class="Slide_02">
    <div class="grid__item swiper-slide">
      <a href="https://www.youtube.com/embed/hEnBGa88rqY" target="_blank" class="grid__item-img">
        <img :src="require('@/assets/images/reviews/reviews_2.png')" class="avatar" alt="Isaac Joshua Chiroma">
        <img :src="require('@/assets/images/play.svg')" class="play" alt="Play video">
      </a>
      <div class="grid__item-caption">
        <div class="grid__item-review">
          {{ $t("home.reviews.review_msg_1") }}
        </div>
        <div class="grid__item-name">
          {{ $t("home.reviews.review_author_1") }}
        </div>
        <a href="https://www.youtube.com/embed/hEnBGa88rqY" target="_blank" class="btn__default light">{{ $t("home.reviews.watch") }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slide_02",
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
  },
};
</script>

<style lang="scss">
/****  Slide_02  ****/
.Slide_02 {
  width: 100%;
  height: auto;
  display: contents;
}
</style>
