<template>
  <div class="MetaverseDescription">
    <div class="description-container">
      <div class="content">
        <div class="description">
          <p>
            {{ $t("home.metaverse.description") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MetaverseDescription",
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
  },
};
</script>

<style lang="scss">
/****  MetaverseDescription  ****/
.MetaverseDescription {
  width: 100%;
  height: auto;

  .description {
    display: flex;
    justify-content: center;
    align-items: start;
    max-width: 90vw;
  }

  .description p {
    max-width: 952px;
    font-size: 40px;
    line-height: 130%;
    margin: 50px 0 50px 0;
    text-align: center;
    font-weight: 400;
    color: #FFFFFF;
    padding: 50px 0 0 50px;
  }
}
</style>
