<template>
  <section class="Products">
    <div class="content">
      <div class="slides stack-cards js-stack-cards">
        <div class="slide stack-cards__item js-stack-cards__item">
          <div class="slide__name">
            <span class="num">1</span>
            <p>{{ $t("home.products.product0_title") }}</p>
          </div>
          <div class="slide__description">
            <p>{{ $t("home.products.product0_desc0") }}</p>
            <p>{{ $t("home.products.product0_desc1") }}</p>
            <p>{{ $t("home.products.product0_desc2") }}</p>
            <p>{{ $t("home.products.product0_desc3") }}</p>
            <p>{{ $t("home.products.product0_desc4") }}</p>
            <span>
              {{ $t("home.products.product0_subdesc") }}
          </span>
            <router-link class="btn__default" :to="{ name: 'HomeUniteverse'}">{{ $t("home.products.more") }}</router-link>
<!--            <a class="btn__default" href="uniteverse.html">More info</a>-->
          </div>
        </div>
        <div class="slide stack-cards__item js-stack-cards__item">
          <div class="slide__name">
            <span class="num">2</span>
            <p>{{ $t("home.products.product1_title") }} <span>{{ $t("home.products.product1_title1") }}</span></p>
          </div>
          <div class="slide__description">
            <p>{{ $t("home.products.product1_desc0") }}</p>
            <p>{{ $t("home.products.product1_desc1") }}</p>
            <p>{{ $t("home.products.product1_desc2") }}</p>
            <p>{{ $t("home.products.product1_desc3") }}</p>
            <p>{{ $t("home.products.product1_desc4") }}</p>
            <span>
              {{ $t("home.products.product1_subdesc") }}
          </span>
            <router-link class="btn__default" :to="{ name: 'HomeTactile'}">{{ $t("home.products.more") }}</router-link>
<!--            <a class="btn__default" href="tactile.html">More info</a>-->
          </div>
        </div>
        <div class="slide stack-cards__item js-stack-cards__item">
          <div class="slide__name">
            <span class="num">3</span>
            <p>{{ $t("home.products.product2_title") }}</p>
          </div>
          <div class="slide__description">
            <p>{{ $t("home.products.product2_desc0") }}</p>
            <p>{{ $t("home.products.product2_desc1") }}</p>
            <p>{{ $t("home.products.product2_desc2") }}</p>
            <p>{{ $t("home.products.product2_desc3") }}</p>
            <span>
              {{ $t("home.products.product2_subdesc0") }}
              <br>
              {{ $t("home.products.product2_subdesc1") }}
              <br>
              {{ $t("home.products.product2_subdesc2") }}
          </span>
            <router-link class="btn__default" :to="{ name: 'HomeBoost'}">{{ $t("home.products.more") }}</router-link>
<!--            <a class="btn__default" href="boost.html">More info</a>-->
          </div>
        </div>
        <div class="slide stack-cards__item js-stack-cards__item">
          <div class="slide__name">
            <span class="num">4</span>
            <p>{{ $t("home.products.product3_title") }}</p>
          </div>
          <div class="slide__description">
            <p>{{ $t("home.products.product3_desc0") }}</p>
            <p>{{ $t("home.products.product3_desc1") }}</p>
            <p>{{ $t("home.products.product3_desc2") }}</p>
            <span>
              {{ $t("home.products.product3_subdesc0") }}
              <br>
              {{ $t("home.products.product3_desc1") }}
          </span>
            <router-link class="btn__default" :to="{ name: 'HomeOverreal'}">{{ $t("home.products.more") }}</router-link>
<!--            <a class="btn__default" href="overreal.html">More info</a>-->
          </div>
        </div>
        <div class="spacer"></div>
      </div>
    </div>
  </section>
</template>

<script>
require("@/assets/js/cardStack.js");

export default {
  name: "Products",
  data() {
    return {
      products: [
        {
          title: "",
          subtitle: "",
          description: "",
        }
      ]
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/cardStack.css";

.Products {
  min-height: 100vh;
  background: #1E2930;
  //overflow: visible;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  max-height: 100vh;
  //padding-top: 100px;
  padding-top: 50px;
  //padding-bottom: 20px;
  background: url("~@/assets/images/home/cards-bg.jpg") center 100px/cover no-repeat, linear-gradient(0, #1E2930 90%, #122837 102%);

  @media (max-width: 992px) {
    padding-top: 12px;
  }

  .slides {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 24px);
    position: relative;

    .slide {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 24px;
      padding: 64px 40px;
      width: 100%;
      background: #FFFFFF;
      border-radius: 32px;
      box-shadow: 0 -5px 10px -10px #000000;
      overflow: hidden;
      //margin-bottom: 50px;

      .slide__description {
        .btn__default {
          padding: 12px 16px;
          font-size: 16px;
          line-height: 100%;
          text-transform: uppercase;
          color: #000000;
          border: 1px solid rgba(0, 0, 0, 0.4);
          border-radius: 100px;
        }
      }

      &__name {
        display: grid;
        grid-template-columns: 60px auto;
        align-self: flex-start;
        grid-gap: 32px;
        font-size: 72px;
        line-height: 110%;
        color: #000000;
        letter-spacing: -0.04em;

        .num {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          font-size: 40px;
          border: 2px solid #000000;
          border-radius: 50%;

          @media (max-width: 992px) {
            width: 32px;
            height: 32px;
            font-size: 24px;
          }
        }

        span:not(.num) {
          color: rgba(0, 0, 0, 0.4);
          white-space: nowrap;
        }

        @media (max-width: 992px) {
          grid-template-columns: 32px auto;
          grid-gap: 12px;
          align-items: center;
          font-size: 44px;
        }

        @media (max-width: 560px) {
          font-size: 32px;
        }
      }

      &__description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 40px;
        line-height: 110%;
        color: #000000;

        span {
          margin: 32px 0;
          font-size: 16px;
          line-height: 130%;
          color: rgba(0, 0, 0, 0.6);

          @media (max-width: 992px) {
            margin: 16px 0;
          }
        }

        @media (max-width: 992px) {
          font-size: 32px;
        }

        @media (max-width: 560px) {
          font-size: 24px;
        }
      }

      @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        grid-gap: 12px;
        padding: 32px;
      }
    }

    .spacer {
      width: 100%;
      height: auto;

      @media (max-width: 992px) {
        display: none;
      }
    }

    @media (max-width: 992px) {
      padding-bottom: 160px !important;
    }
  }

  @media (max-width: 1366px) {
    background: url("~@/assets/images/home/cards-bg.jpg") center 100px/cover no-repeat, #1E2930;
  }

  @media (max-width: 992px) {
    height: auto;
    background: #1E2930;
  }
}
</style>
