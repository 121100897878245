<template>
  <div class="Slide_03">
    <div class="grid__item swiper-slide">
      <a href="https://www.youtube.com/embed/_tD1zLijM3I" target="_blank" class="grid__item-img">
        <img :src="require('@/assets/images/reviews/reviews_3.png')" class="avatar" alt="Mulalo">
        <img :src="require('@/assets/images/play.svg')" class="play" alt="Play video">
      </a>
      <div class="grid__item-caption">
        <div class="grid__item-review">
          {{ $t("home.reviews.review_msg_2") }}
        </div>
        <div class="grid__item-name">
          {{ $t("home.reviews.review_author_2") }}
        </div>
        <a href="https://www.youtube.com/embed/_tD1zLijM3I" target="_blank" class="btn__default light">{{ $t("home.reviews.watch") }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slide_03",
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
  },
};
</script>

<style lang="scss">
/****  Slide_03  ****/
.Slide_03 {
  width: 100%;
  height: auto;
  display: contents;
}
</style>
