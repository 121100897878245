<template>
  <div class="AppDocSlider" @wheel="handleScroll">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AppDocSlider",
  components: {},
  props: ['config'],
  data() {
    return {
      slides: null,
      observerOptions: {
        root: null,
        rootMargin: '0px',
        threshold: [0.1]
      },
      observer: null,
      scrollDisabled: false,
      currentBlockIdx: 0,
    };
  },
  computed: {},
  methods: {
    scrollTo(el, pos) {
      this.scrollDisabled = true;
      setTimeout(()=> {
        el.scrollIntoView({
          // behavior: "smooth",
          block: pos
          // block: 'nearest'
        })
      },80);

      setTimeout(()=> {
        this.scrollDisabled = false;
      },500);
    },
    check(el) {
      el = el.filter(v=>v.isIntersecting)
      if(el.length) {
        let {target: target, rootBounds: root, boundingClientRect: client} = el[0];

        let tIdx = this.slides.findIndex((v)=>v===target);
        let dir = tIdx > this.currentBlockIdx ? 1 : -1;
        let heightRatio = root.height / client.height < 1 ? -1 : 1;
        let pos = dir*heightRatio < 0 ? 'start' : 'end' ;
        this.scrollTo(target, pos);
        this.currentBlockIdx = tIdx;
      }
    },
    observerInit(){
      this.observer = new IntersectionObserver(this.check, this.observerOptions);
      this.slides.forEach(v=>{
        this.observer.observe(v);
      });
    },
    handleScroll(event) {
      if (this.scrollDisabled) {
      event.preventDefault();
    }
      // if (!this.scrollDisabled) {
      //   event.deltaY > 0 ? window.scrollBy(0, window.innerHeight) : window.scrollBy(0, -window.innerHeight);
      // }
    },
  },
  mounted() {
    if(this.$slots?.default?.[0]) {
      this.slides = this.$slots.default.map(v=>v.elm);
    }
    this.observerInit();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
};
</script>

<style lang="scss">
/****  AppDocSlider  ****/

html {
  scroll-behavior: smooth;
}

.AppDocSlider {
  width: 100%;
  height: auto;
}
</style>
