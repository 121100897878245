<template>
  <section class="Roadmap">
    <div class="token"><img src="@/assets/images/home/token.png" alt="token"></div>
    <div class="description">
      <div class="title">
        {{ $t("home.roadmap.description") }}
      </div>
      <p>
        {{ $t("home.roadmap.subdescription") }}
      </p>
    </div>
    <div class="steps__wrapper">
      <div class="steps">
        <div class="steps__item">
          <div class="steps__item-icon">
            <svg-vue icon="step-orange" />
          </div>
          <div class="steps__item-name">
            {{ $t("home.roadmap.step0") }}
          </div>
          <div class="steps__item-date">
            08.08.2021
          </div>
        </div>
        <div class="steps__item">
          <div class="steps__item-icon">
            <svg-vue icon="step-orange" />
          </div>
          <div class="steps__item-name">
            {{ $t("home.roadmap.step1") }}
          </div>
          <div class="steps__item-date">
            28.06.2022
          </div>
        </div>
        <div class="steps__item">
          <div class="steps__item-icon">
            <svg-vue icon="step-orange" />
          </div>
          <div class="steps__item-name">
            {{ $t("home.roadmap.step2") }}
          </div>
          <div class="steps__item-date">
            31.12.2022
          </div>
        </div>
        <div class="steps__item">
          <div class="steps__item-icon">
            <svg-vue icon="step-orange" class="coreOrange" />
            <!-- <svg-vue icon="step-green" /> -->
          </div>
          <div class="steps__item-name">
            {{ $t("home.roadmap.step3") }}
          </div>
          <div class="steps__item-date">
            08.02.2023
          </div>
        </div>
        <div class="steps__item">
          <div class="steps__item-icon greenNimb">
            <svg-vue icon="step-green2" />
            <svg-vue icon="step-green" class="secondWithNimb" />
          </div>
          <div class="steps__item-name">
            {{ $t("home.roadmap.step4") }}
            <br>
            {{ $t("home.roadmap.step5") }}
          </div>
          <div class="steps__item-date">
            24.04.2023
          </div>
          <span>We are here</span>
        </div>
        <div class="steps__item">
          <div class="steps__item-icon">
            <svg-vue icon="step-grey-reverse" />
          </div>
          <div class="steps__item-name">
            {{ $t("home.roadmap.step6") }}
          </div>
        </div>
        <div class="steps__item">
          <div class="steps__item-icon">
            <svg-vue icon="step-grey-reverse" />
          </div>
          <div class="steps__item-name">
            {{ $t("home.roadmap.step7") }}
          </div>
        </div>
        <div class="steps__item">
          <div class="steps__item-icon">
            <svg-vue icon="step-grey" />
            <svg-vue icon="step-grey-reverse" />
          </div>
          <div class="steps__item-name">
            {{ $t("home.roadmap.step8") }}
            <br>
            {{ $t("home.roadmap.step9") }}
          </div>
        </div>
        <div class="steps__item">
          <div class="steps__item-icon">
            <svg-vue icon="step-grey-reverse" />
          </div>
          <div class="steps__item-name">
            {{ $t("home.roadmap.step10") }}
          </div>
        </div>
        <div class="steps__item">
          <div class="steps__item-icon">
            <svg-vue icon="step-grey-reverse" />
          </div>
          <div class="steps__item-name">
            {{ $t("home.roadmap.step11") }}
          </div>
        </div>
        <div class="steps__item">
          <div class="steps__item-icon">
            <svg-vue icon="step-grey-reverse" />
          </div>
          <div class="steps__item-name">
            {{ $t("home.roadmap.step12") }}
          </div>
        </div>
        <div class="steps__item">
          <div class="steps__item-icon">
            <svg-vue icon="step-grey-reverse" />
          </div>
          <div class="steps__item-name">
            {{ $t("home.roadmap.step13") }}
          </div>
        </div>
        <div class="arrow-curved">
          <span></span>
          <span></span>
        </div>
        <div class="arrow-curved arrow-md">
          <span></span>
          <span></span>
        </div>
        <div class="arrow-curved arrow-sm">
          <span></span>
          <span></span>
        </div>
        <div class="arrow-curved arrow-xs">
          <span></span>
          <span></span>
        </div>
        <div class="arrow-curved arrow-xxs">
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="steps-bg">
        <img src="@/assets/images/home/steps-bg.png" alt="">
      </div>
  </div>
  </section>
</template>

<script>
export default {
  name: "Roadmap"
}
</script>

<style lang="scss">
.Roadmap {
  min-height: 100vh;
  background: #1E2930;
  overflow: visible;
  position: relative;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 15vh;
  text-align: center;

  @media (min-width: 993px) {
    scale: 1.0;
    //opacity: 0;
  }

  @media (max-width: 560px) {
    padding-top: 0;
  }

  .token {
    position: relative;
    top: -25px;
    margin-bottom: 70px;

    img {
      max-width: 100%;
      z-index: 1;
    }
  }


  .description {
    display: flex;
    flex-direction: column;
    max-width: 1037px;
    padding: 0 16px;

    .title {
      margin-bottom: 16px;
      position: relative;
      font-size: 40px;
      line-height: 110%;
      letter-spacing: -0.04em;
      z-index: 2;

      @media (max-width: 768px) {
        font-size: 32px;
      }

      @media (max-width: 560px) {
        font-size: 24px;
      }
    }

    p {
      position: relative;
      font-size: 24px;
      line-height: 130%;
      color: rgba(255, 255, 255, 0.6);
      z-index: 2;

      @media (max-width: 560px) {
        font-size: 20px;
      }
    }
  }

  .steps__wrapper {
    position: relative;
    width: 100%;
    padding: 120px 0 40px;

    .steps {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 18px;
      grid-row-gap: 88px;
      position: relative;
      max-width: 1360px;
      margin: 0 auto;
      padding: 16px 32px 16px 16px;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(80px);
      border-radius: 24px;
      text-align: left;
      z-index: 1;

      &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;

        .greenNimb {
          .secondWithNimb {
            position: absolute;
            top: 0;
            left: 30px;
          }

        }

        &-icon {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          width: 100%;
          height: 42px;
          margin-bottom: 30px;
          position: relative;

          .coreOrange {
            position: absolute;
            top: 6px;
            left: 10px;
          }

          &::after {
            position: absolute;
            width: 100%;
            height: 12px;
            background: url("~@/assets/images/home/arrow.svg") right center no-repeat;
            content: '';
            z-index: -1;
          }

          @media (max-width: 1200px) {
            margin-bottom: 12px;
          }
        }

        &-name {
          margin-bottom: 4px;
          font-size: 16px;
          line-height: 130%;
          color: #FFFFFF;

          @media (max-width: 1366px) {
            font-size: 14px;
          }

          @media (max-width: 560px) {
            font-size: 13px;
          }
        }

        &-date {
          font-size: 14px;
          line-height: 110%;
          letter-spacing: -0.02em;
          color: rgba(255, 255, 255, 0.6);

          @media (max-width: 1366px) {
            font-size: 12px;
          }
        }

        span {
          position: absolute;
          top: 9px;
          left: 78px;
          // left: 45px;
          padding: 4px 8px;
          background: #70FDA5;
          font-size: 14px;
          line-height: 110%;
          text-align: center;
          letter-spacing: -0.02em;
          color: #1A1A1A;
          border-radius: 32px;

          @media (max-width: 560px) {
            font-size: 10px;
          }
        }

        &:nth-child(5),
        &:nth-child(8) {
          .steps__item-icon {
            img:nth-child(2) {
              margin-left: -13px;
            }
          }
        }

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(12) {
          .steps__item-icon {
            &::after {
              display: none;

              @media (max-width: 1200px) {
                display: block;
              }
            }
          }
        }

        &:nth-child(2) {
          .steps__item-icon {
            &::after {
              @media (max-width: 560px) {
                display: none;
              }
            }
          }
        }

        &:nth-child(3) {
          .steps__item-icon {
            &::after {
              @media (max-width: 992px) {
                display: none;
              }

              @media (max-width: 560px) {
                display: block;
              }
            }
          }
        }

        &:nth-child(4) {
          .steps__item-icon {
            img {
              margin-left: -13px;
            }

            &::after {
              @media (max-width: 1200px) {
                display: none;
              }

              @media (max-width: 992px) {
                display: block;
              }

              @media (max-width: 560px) {
                display: none;
              }
            }
          }
        }

        &:nth-child(6) {
          .steps__item-icon {
            &::after {
              @media (max-width: 992px) {
                display: none;
              }
            }
          }
        }

        &:nth-child(8) {
          .steps__item-icon {
            &::after {
              @media (max-width: 1200px) {
                display: none;
              }

              @media (max-width: 992px) {
                display: block;
              }

              @media (max-width: 560px) {
                display: none;
              }
            }
          }
        }

        &:nth-child(9) {
          .steps__item-icon {
            &::after {
              @media (max-width: 992px) {
                display: none;
              }

              @media (max-width: 560px) {
                display: block;
              }
            }
          }
        }

        &:nth-child(10) {
          .steps__item-icon {
            &::after {
              @media (max-width: 560px) {
                display: none;
              }
            }
          }
        }

        &:nth-child(12) {
          .steps__item-icon {
            &::after {
              @media (max-width: 1200px) {
                display: none;
              }
            }
          }
        }
      }

      .arrow-curved {
        position: absolute;
        right: 40px;
        top: 35px;
        width: 92%;
        height: 130px;
        content: "";
        z-index: -1;
        border: 2px solid rgba(255, 255, 255, 0.2);
        border-top: none;
        border-left: none;
        border-radius: 24px 24px 24px 0;

        span:first-child {
          position: absolute;
          bottom: -38px;
          left: -31px;
          width: 31px;
          height: 38px;
          border-top: 2px solid rgba(255, 255, 255, 0.2);
          border-radius: 24px 0 0 0;
        }

        span:nth-child(2) {
          position: absolute;
          bottom: -58px;
          left: -34.5px;
          width: 9px;
          height: 30px;
          background: url("~@/assets/images/home/arrow.svg") right center no-repeat;
          transform: rotate(90deg);

          @media (max-width: 1200px) {
            left: -34px;
          }

          @media (max-width: 992px) {
            bottom: -57px;
          }
        }

        &::before {
          position: absolute;
          top: 0;
          right: 0;
          width: 350px;
          height: 135px;
          border-top: 2px solid rgba(255, 255, 255, 0.2);
          content: "";
          border-radius: 0 24px;

          @media (max-width: 1200px) {
            width: 140px;
          }

          @media (max-width: 560px) {
            width: 120px;
          }
        }

        &::after {
          position: absolute;
          bottom: -38px;
          left: -31px;
          width: 50px;
          height: 38px;
          border-left: 2px solid rgba(255, 255, 255, 0.2);
          content: "";
          border-radius: 24px 0 0 0;
        }

        &.arrow-md {
          display: none;

          @media (max-width: 1200px) {
            display: block;
            top: 214px;
          }

          @media (max-width: 560px) {
            top: 232px;
            right: 23px;
            height: 100px;
            width: 72%;
          }
        }

        &.arrow-sm {
          display: none;

          @media (max-width: 992px) {
            display: block;
            top: 414px;
          }

          @media (max-width: 560px) {
            top: 405px;
            right: 23px;
            width: 72%;
          }
        }

        &.arrow-xs {
          display: none;

          @media (max-width: 560px) {
            display: block;
            top: 602px;
            width: 72%;
          }
        }

        &.arrow-xxs {
          display: none;

          @media (max-width: 560px) {
            display: block;
            top: 799px;
            width: 72%;
            height: 91px;
          }
        }

        @media (max-width: 1366px) {
          width: 91%;
        }

        @media (max-width: 1200px) {
          width: 88.8%;
          height: 100px;
        }

        @media (max-width: 992px) {
          width: 81%;
          height: 100px;
        }

        @media (max-width: 560px) {
          top: 36px;
          right: 23px;
          width: 74%;
          height: 125px;
        }
      }

      @media (max-width: 1366px) {
        max-width: 1200px;
      }

      @media (max-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
        max-width: 960px;
      }

      @media (max-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
        max-width: 560px;
      }

      @media (max-width: 560px) {
        grid-template-columns: repeat(2, 1fr);
        max-width: 320px;
      }
    }

    .steps-bg {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 870px;

      img {
        width: 100%;
      }
    }

    @media (max-width: 560px) {
      padding: 40px 0;
    }
  }

  @media (max-width: 992px) {
    height: auto;
    padding: 80px 0;
  }

  @media (max-width: 560px) {
    height: auto;
    padding: 0;
  }
}
</style>
