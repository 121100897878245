<template>
  <section class="Reviews">
    <div class="content">
      <div class="title">
        <h2>{{ $t("home.reviews.title") }}</h2>
      </div>
      <Swiper  class="swiper grid" ref="homeSwiper" :options="swiperOptions">
<!--        v-for="(p, idx) in Pages" :key="p.block.name + idx"-->
        <swiper-slide class="slide-outer">
          <Slide_01 />
        </swiper-slide>
        <swiper-slide class="slide-outer">
          <Slide_02 />
        </swiper-slide>
        <swiper-slide class="slide-outer">
          <Slide_03 />
        </swiper-slide>
        <swiper-slide class="slide-outer">
          <Slide_04 />
        </swiper-slide>
        <swiper-slide class="slide-outer">
          <Slide_05 />
        </swiper-slide>
        <swiper-slide class="slide-outer">
          <Slide_06 />
        </swiper-slide>
<!--        slot="pagination"-->
        <div class="swiper-pagination"></div>
      </Swiper>
    </div>
  </section>
</template>

<script>
import {Swiper, SwiperSlide,
  // directive
} from 'vue-awesome-swiper';


import 'swiper/css/swiper.css';
import '@/assets/scss/swiper-fix.scss';

import Slide_01 from '@/components/Home/ReviewSlides/Slide_01.vue';
import Slide_02 from '@/components/Home/ReviewSlides/Slide_02.vue';
import Slide_03 from '@/components/Home/ReviewSlides/Slide_03.vue';
import Slide_04 from '@/components/Home/ReviewSlides/Slide_04.vue';
import Slide_05 from '@/components/Home/ReviewSlides/Slide_05.vue';
import Slide_06 from '@/components/Home/ReviewSlides/Slide_06.vue';

export default {
  name: "Reviews",
  components: {
    Swiper, SwiperSlide,
    Slide_01, Slide_02, Slide_03, Slide_04, Slide_05, Slide_06,
  },
  data() {
    return {
      loading: false,
      swiperOptions: {
        // initialSlide: 2,
        // runCallbacksOnInit: true,
        // observer: true,
        // observeParents: true,
        // observeSlideChildren: true,
        speed: 900,
        updateOnWindowResize: true,
        autoplay: {
          delay: 4500,
          // disableOnInteraction: false,
        },
        centeredSlides: false,
        slidesPerView: 1.0,
        spaceBetween: 10,
        effect: 'coverflow',
        // effect: 'flip',
        coverflowEffect: {
          rotate: 30,
        },
        loop: true,
        // slideToClickedSlide: true,
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
        grabCursor: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    };
  },
  computed:{
    swiper() {
      return this.$refs.homeSwiper.$swiper;
    },

  },
}
</script>

<style lang="scss">
.Reviews {
  width: 100%;
  //max-width: 1032px;
  height: auto;
  min-height: auto;
  background: #1E2930;
  padding: 100px 32px 0;
  margin-bottom: 15px;

  .btn__default {
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: #FFFFFF;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 100%;
    text-transform: uppercase;
    border-radius: 100px;
  }

  .content {
    flex-flow: column nowrap;
    //align-items: center;
  }
  h2 {
    //white-space: nowrap;
    font-weight: 400;
  }
  .swiper {
    padding-bottom: 65px;

  }
  .slide-outer {
    width: 100%;
    height: auto;
    min-height: 360px;

  }

  @media screen and (max-width: 1200px) {
    .grid__item-img {
      height: 67% !important;
    }
  }
  @media screen and (max-width: 769px) {
    .grid__item-img {
      height: 50% !important;
    }
  }
  @media screen and (max-width: 560px) {
    .grid__item-img {
      width: 50% !important;
      height: 110% !important;
      justify-self: center !important;
      margin-bottom: 20px !important;
    }
  }
}
</style>
