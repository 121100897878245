<template>
  <section class="Intro">
    <div class="intro-bg">
      <img src="@/assets/images/home/bg.jpg" alt="">
    </div>
    <div class="title">
      <p v-html="$t('home.intro.title')"></p>
    </div>

    <div class="tip">
      <p v-html="$t('home.intro.subtitle')"></p>
    </div>
  </section>
</template>

<script>
export default {
  name: "Intro",
}
</script>

<style lang="scss">
.Intro {
  height: 100vh;
  background: #1E2930;
  overflow: visible;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .title {
    max-width: 735px;
    position: absolute;
    bottom: 70px;
    font-size: 72px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.04em;
    color: #FFFFFF;
    //opacity: 0;
    animation: 1s linear 0s forwards intro-title;
    //z-index: 1;

    @media (max-width: 992px) {
      font-size: 68px;
    }

    @media (max-width: 768px) {
      padding: 0 15px;
      font-size: 54px;
    }

    @media (max-width: 560px) {
      bottom: 90px;
      font-size: 32px;
    }
  }

  .tip {
    max-width: 612px;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    bottom: 30px;
    //opacity: 0;
    animation: 1s linear 0s forwards intro-tip;
    //z-index: 1;

    @media (max-width: 768px) {
      padding: 0 15px;
      font-size: 14px;
    }
  }

  .intro-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(0);
    transition: .6s ease;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      object-fit: cover;
      scale: 1;
      top: 0;
      transition: .6s ease;
    }
  }

  @media (max-width: 768px) {
    animation: none;
    background-size: 150% 120%;
  }
}
</style>
