<template>
    <AppDocSlider class="Home"
                  :config="docSliderConfig">
      <Intro id="intro"></Intro>
      <Roadmap id="description"></Roadmap>
      <Products id="slides"></Products>
      <Metaverse id="welcome"></Metaverse>
      <MetaverseDescription id="welcome-description"></MetaverseDescription>
      <News id="news"></News>
      <Academy id="academy"></Academy>
      <Reviews id="reviews"></Reviews>
      <FAQ id="faq"></FAQ>
    </AppDocSlider>
</template>

<script>
import Intro from "@/components/Home/Intro.vue";
import Roadmap from "@/components/Home/Roadmap.vue";
import Products from "@/components/Home/Products.vue";
import Metaverse from "@/components/Home/Metaverse.vue";
import MetaverseDescription from "@/components/Home/MetaverseDescription.vue";
import News from "@/components/Home/News.vue";
import Academy from "@/components/Home/Academy.vue";
import Reviews from "@/components/Home/Reviews.vue";
import FAQ from "@/components/Home/FAQ.vue";

import AppDocSlider from "@/components/UI/AppDocSlider.vue";
// import {Lethargy} from "@/assets/js/lethargy.min.js";
// import docSlider from "@/assets/js/docSlider.js";
//
// window.lethargy = new Lethargy(7, 100, 0.05);

export default {
  name: "Home",
  components: {FAQ, Reviews, Academy, News, Metaverse, MetaverseDescription, Products, Roadmap, Intro,
    AppDocSlider, },
  data(){
    return {
      docSliderConfig: {},
    }
  },
  mounted(){
    // if (media.matches) {
    //   docSlider.enable();
    // } else {
    //  docSlider.init({
    //     pager: false,
    //     // pager: true,
    //     scrollReset: true,
    //   });
    // }
  },

}
</script>

<style lang="scss">
.Home {
  background: #1E2930;
  font-family: "Suisse Int", sans-serif;
  font-weight: 400;
  width: 100%;
  //overflow-x: hidden;
  //overflow-y: auto;
  //overflow-y: overlay;

  @media (max-width: 768px) {
    background-size: 200% 100%;
  }

  h1,
  h2 {
    font-size: 72px;
    font-weight: 400;

    @media (max-width: 560px) {
      font-size: 32px;
    }
  }

  h3,
  h4 {
    font-weight: 400;
  }
  section .content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    //height: 100%;
    position: relative;
  }



  section#slides {
    background: #1E2930;
    //background: url(../img/cards-bg.jpg) center bottom / cover no-repeat;
    //background: url(../img/cards-bg.jpg) center 100px/cover no-repeat, linear-gradient(0, #1E2930 90%, #122837 102%);

    .content {
      flex-direction: column;
      justify-content: flex-start;
      height: auto;
      padding-top: 100px;

      @media (max-width: 992px) {
        padding-top: 12px;
      }
    }

    .slides {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 24px);
      position: relative;

      .slide {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
        padding: 64px 40px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 32px;
        box-shadow: 0 -5px 10px -10px #000000;
        overflow: hidden;

        &__name {
          display: grid;
          grid-template-columns: 60px auto;
          align-self: flex-start;
          grid-gap: 32px;
          font-size: 72px;
          line-height: 110%;
          color: #000000;
          letter-spacing: -0.04em;

          .num {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            font-size: 40px;
            border: 2px solid #000000;
            border-radius: 50%;

            @media (max-width: 992px) {
              width: 32px;
              height: 32px;
              font-size: 24px;
            }
          }

          span:not(.num) {
            color: rgba(0, 0, 0, 0.4);
            white-space: nowrap;
          }

          @media (max-width: 992px) {
            grid-template-columns: 32px auto;
            grid-gap: 12px;
            align-items: center;
            font-size: 44px;
          }

          @media (max-width: 560px) {
            font-size: 32px;
          }
        }

        &__description {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 40px;
          line-height: 110%;
          color: #000000;

          span {
            margin: 32px 0;
            font-size: 16px;
            line-height: 130%;
            color: rgba(0, 0, 0, 0.6);

            @media (max-width: 992px) {
              margin: 16px 0;
            }
          }

          @media (max-width: 992px) {
            font-size: 32px;
          }

          @media (max-width: 560px) {
            font-size: 24px;
          }
        }

        @media (max-width: 992px) {
          display: flex;
          flex-direction: column;
          grid-gap: 12px;
          padding: 32px;
        }
      }

      .spacer {
        width: 100%;
        height: 38vh;

        @media (max-width: 992px) {
          display: none;
        }
      }

      @media (max-width: 992px) {
        padding-bottom: 160px!important;
      }
    }

    @media (max-width: 1366px) {
      //background: url(../img/cards-bg.jpg) center 100px/cover no-repeat, #1E2930;
    }

    @media (max-width: 992px) {
      height: auto;
      background: #1E2930;
    }
  }

  section#reviews {
    background: #1E2930;
    padding: 0 32px;

    .content {
      flex-direction: column;
      justify-content: flex-start;
      height: auto;
      padding-top: 100px;

      @media (max-width: 992px) {
        padding-top: 40px;
      }
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 40px;

      h2 {
        font-weight: 400;
        font-size: 72px;
        line-height: 110%;
        letter-spacing: -0.04em;
        color: #FFFFFF;

        @media (max-width: 768px) {
          font-size: 60px;
        }

        @media (max-width: 560px) {
          font-size: 32px;
        }
      }
    }

    .grid {
      width: 100%;
      max-width: 1032px;
      margin: auto 0;

      &__item {
        display: grid;
        grid-template-columns: 360px auto;
        grid-gap: 64px;
        background: #1E2930;

        &-img {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: auto;
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;
          border: 1px solid rgba(255, 255, 255, 0.2);

          .avatar {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .play {
            position: absolute;
          }
        }

        &-caption {
          display: flex;
          flex-direction: column;
          align-items: start;
        }

        &-review {
          font-size: 32px;
          line-height: 130%;
          color: #FFFFFF;

          @media (max-width: 768px) {
            font-size: 24px;
          }

          @media (max-width: 560px) {
            text-align: center;
          }
        }

        &-name {
          margin: 8px 0 32px;
          font-size: 24px;
          line-height: 110%;
          color: rgba(255, 255, 255, 0.6);

          @media (max-width: 992px) {
            font-size: 20px;
          }

          @media (max-width: 560px) {
            margin: 8px auto 32px;
          }
        }

        .btn__default {
          @media (max-width: 560px) {
            margin: 0 auto;
          }
        }

        @media (max-width: 1200px) {
          grid-template-columns: 240px auto;
        }

        @media (max-width: 768px) {
          grid-template-columns: 180px auto;
          align-items: flex-start;
          grid-gap: 32px;
        }

        @media (max-width: 560px) {
          grid-template-columns: none;
        }
      }

      @media (max-width: 992px) {
        padding-bottom: 48px;
      }
    }

    @media (max-width: 992px) {
      height: auto;
    }
  }


}
</style>
