<template>
  <section class="Metaverse">
    <div class="video-container content">
      <div class="bg">
        <video autoplay="autoplay" muted="muted" loop="loop" playsinline="" preload="auto" id="gameplay" class="video">
          <source src="@/assets/video/gameplay.mp4" type="video/mp4">
        </video>
      </div>
      <div class="title">
        <p>{{ $t("home.metaverse.title") }}</p>
        <p><span>{{ $t("home.metaverse.subtitle") }}</span></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Metaverse"
}
</script>

<style lang="scss">
.Metaverse {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;

  .video-container {
    width: calc(100% - 60px);
    min-height: 100vh;
    background: #1E2930;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: auto;
    border-radius: 30px;
  }
  .content {
    width: calc(100% - 35px) !important;
    //min-height: 100vh;
    background: #1E2930;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: auto;
  }

  .title {
    text-align: center;
    color: #FFFFFF;
    //z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    p:first-child {
      font-size: 72px;
      line-height: 110%;
      letter-spacing: -0.04em;
    }

    span {
      font-size: 32px;
      line-height: 110%;
      letter-spacing: -0.02em;
    }

    @media (max-width: 768px) {
      font-size: 64px;
    }
  }

  .bg {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: 0.6s ease;
    border-radius: 0;
    overflow: hidden;
    margin: auto;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      scale: 1.2;
      animation: 1s linear 0s forwards welcome-bg-reverse;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      content: '';
      opacity: 1;
      transition: .3s ease;
    }
  }

}
</style>
