<template>
  <section class="News">
    <div class="news-wrapper">
      <div class="title">
        <h2>{{ $t("home.news.title") }}</h2>
      </div>
      <div class="list">
        <div class="list__item"
             v-for="post in news"
             :key="post.id"
             data-aos="fade-up"
             data-aos-duration="900">
          <div class="list__item-name">
           <div class="list__item-name-inner">
            <img :src="post.img" class="list__item-name-preview">
            <div class="list__item-name-title">
              <router-link :to="`/academy/post/${post.id}`">{{ $i18n.locale === 'ru' ? post.name_ru : post.name }}</router-link>
              <div class="list__item-info">
                <span>{{ formattedDate(post.created) }}</span>
                <span>{{ $t("home.news.investors") }}</span>
                <span>{{ $t("home.news.capital") }}</span>
              </div>
            </div>
            </div>
          </div>
          <div class="list__item-description">
            {{ $i18n.locale === 'ru' ? post.excerpt_ru : post.excerpt }}
             <router-link :to="`/academy/post/${post.id}`" target="_blank">
              <button class="list__item-btn">
                <span>Read article</span>
                <BaseIcon name="arrowNext" verAlign/>
              </button>
            </router-link>
          </div>
        </div>
      </div>
      <router-link :to="{name: 'showCategory', params: { id: '2' }}" class="view__all">{{ $t("home.news.view_all") }}</router-link>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import BaseIcon from '@/components/UI/BaseIcon.vue'

export default {
  name: "News",
  components: {
    BaseIcon
  },
  mounted() {
    this.$store.dispatch("fetchLandingNewsPosts", {limit: 3})
  },
  computed: {
    ...mapGetters({
      news: "allNewsPosts",
    }),
  },
  methods: {
    formattedDate(date) {
      return this.$moment(date * 1000).format("DD MMMM YYYY")
    },
  },
}
</script>

<style lang="scss">
.News {
  background: #1E2930;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  padding: 100px 32px 0;
  color: #000;

  @media (max-width: 992px) {
    padding-top: 20px;
  }

  .news-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 40px 0;
    background: #FFFFFF;
    border-radius: 32px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      h2 {
        width: 100%;
        padding-bottom: 40px;
        font-weight: 400;
        font-size: 72px;
        line-height: 110%;
        letter-spacing: -0.04em;
        color: #000000;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        @media (max-width: 768px) {
          padding-bottom: 12px;
          font-size: 60px;
        }

        @media (max-width: 560px) {
          font-size: 32px;
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      &__item {
        display: grid;
        grid-template-columns: repeat(2, 2fr);
        grid-gap: 32px;
        width: 100%;
        padding: 56px 0;

        &-name {
          a {
            font-size: 32px;
            line-height: 110%;
            letter-spacing: -0.02em;
            color: #000000;

            @media (max-width: 560px) {
              font-size: 18px;
            }
          }
        }
        &-name-inner {
          display: flex;
          @media (max-width: 560px) {
            flex-direction: column;
          }
        }

        &-name-preview {
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 200px;
          margin-right: 20px;
          border-radius: 12px;
          @media (max-width: 560px) {
            max-width: 100%;
          }
        }

        &-name-title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          @media (max-width: 560px) {
            margin-top: 20px;
          }
        }

        &-description {
          display: flex;
          flex-direction: column;
          justify-content: center;
          @media (max-width: 560px) {
            font-size: 14px;
          }
        }

        &-btn {
          display: flex;
          margin-top: 20px;
          padding: 12px 16px;
          font-size: 16px;
          line-height: 100%;
          text-transform: uppercase;
          color: #000000;
          border: 1px solid rgba(0, 0, 0, 0.4);
          border-radius: 100px;
          max-width: max-content;
        }

        &-info {
          display: flex;
          flex-wrap: nowrap;
          margin-top: 24px;
          font-size: 16px;
          line-height: 130%;
          color: rgba(0, 0, 0, 0.6);

          span {
            padding-right: 24px;

            @media (max-width: 560px) {
              padding-right: 12px;
            }
          }

          span + span {
            padding: 0 24px;
            border-left: 1px solid rgba(0, 0, 0, 0.2);

            @media (max-width: 560px) {
              padding: 0 12px;
            }
          }

          @media (max-width: 560px) {
            font-size: 12px;
          }
        }

        & + .list__item {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          grid-gap: 12px;
          padding: 12px 0;
        }
      }
    }

    .view__all {
      width: 100%;
      padding: 24px;
      font-size: 16px;
      line-height: 100%;
      text-transform: uppercase;
      text-align: center;
      color: #000000;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 768px) {
      padding: 24px 24px 0;
    }
  }

  @media (max-width: 992px) {
    height: auto;

    .clearfix {
      display: none;
    }
  }
}
</style>
